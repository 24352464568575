<template>
	<div class="homepage">
		<audio ref="getVoice">
			<source src="../assets/voice/voice.mp3" type="audio/mpeg">
		</audio>
		<div class="nav">
			<div class="cen-nav dp-flex-row">
				<img class="img-logo" :src="pcLogoUrl" alt="">
				<div class="nav-content">
					<ul class="dp-flex-row">
						<li v-for="(item, index) in navList" :key="index">
							<div class="navitem active" href="#" v-if="index == 0">
								{{ item.navName }}
								<img :src="item.imgSrc" alt="" v-if="item.imgSrc">
								<div v-if="navIndex == index" class="navline"></div>
							</div>
							<!-- {{item.url}} -->
							<!-- <div class="navitem" :href="item.url" target="_blank" v-if="index>0" @click="changeClick(item)"> -->

							<div class="navitem" id="navALL" v-if="index > 0" @click="changeClick(item)">
								{{ item.navName }}
								<img :src="item.imgSrc" alt="" v-if="item.imgSrc">
								<div v-if="navIndex == index" class="navline"></div>
							</div>
						</li>
					</ul>
				</div>
				<!-- <div class="langBox dp-flex-row flex-ai-center">
					<img :src="require('../assets/images/'+selLanguage.icon)" alt="">
					<el-dropdown trigger="click" class="selLang" @command="changeLaguages">
						<span class="el-dropdown-link">
							{{selLanguage.label}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item in langOptions" :key="item.value" :command="item">
								{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div> -->
			</div>
		</div>
		<div class="main">
			<div class="pic-box">
				<div class="pc-box flex-js-around flex">
					<div v-if="num_a==99">
						<img src="../assets/pc/bg-w.png" alt="" />
					</div>
					<div v-else>
						<img :src="require('../assets/num/'+num_a+'.png')" alt="" />
					</div>

					<div v-if="num_b==99">
						<img src="../assets/pc/bg-w.png" alt="" />
					</div>
					<div v-else>
						<img :src="require('../assets/num/'+num_b+'.png')" alt="" />
					</div>

					<div v-if="num_c==99">
						<img src="../assets/pc/bg-w.png" alt="" />
					</div>
					<div v-else>
						<img :src="require('../assets/num/'+num_c+'.png')" alt="" />
					</div>

					<div v-if="num_d==99">
						<img src="../assets/pc/bg-w.png" alt="" />
					</div>
					<div v-else>
						<img :src="require('../assets/num/'+num_d+'.png')" alt="" />
					</div>
				</div>
			</div>

			<div class="input-box">
				<div class="flex item-box flex-js-between">
					<div>
						<div class="title">เลขท้าย2ตัวแรก</div>
						<div><input class="item-inp pr" type="number" placeholder="" v-model="first_2" /></div>
					</div>
					<div>
						<div class="title txt">เลขท้าย2ตัวหลัง</div>
						<div><input class="item-inp pl" type="number" placeholder="" v-model="last_2" /></div>
					</div>
				</div>
				<div>
					<div class="title">ยูสเซอร์เนมสมาชิก</div>
					<div><input class="item-inp" type="text" placeholder="" v-model="username" /></div>
				</div>
			</div>

			<div class="btn-box" @click="submit()">ส่ง</div>

			<div class="record" @click="isQueryDia = true">ประวัติทายผล</div>

			<div class="tip">ข้อกำหนดและเงื่อนไขกิจกรรม:</div>

			<div class="con">
				1. จำกัด VIP2 ขึ้นไปในการเข้าร่วมในกิจกรรมนี้<br />
				2. ยูสเซอร์เนมสามารถทำนายได้ 1 ครั้ง / 1 หมายเลขในแต่ละงวด เท่านั้น<br />
				3. ออกรางวัลตามเลขท้ายสองตัวในผลการจับสลากกินแบ่งรัฐบาลอย่างเป็นทางการ<br />
				4. ทายเลขสองตัวแรกหรือสองตัวหลังเพียงอย่างเดียว รับโบนัส 500 บาท และถอนเงินได้โดยตรงไม่ต้องทำเทิร์น<br />
				5. หากเลขสองตัวแรกและสองตัวท้ายทายถูกพร้อมกัน จะได้รับโบนัส 1000 บาท
				และสามารถถอนเงินได้โดยตรงไม่ต้องทำเทิร์น<br />
				6. เวลาสิ้นสุดการทำนายคือวันที่ 15 และ 30 ของแต่ละเดือน<br />
				7.โปรโมชั่นนี้ขอสงวน.สิทธิ์ ต่อ 1 ชื่อผู้ใช้/1 สิทธิ์/1 IP address/1 อุปกรณ์/ครัวเรือน กรณีสมรู้ร่วมคิด
				ฉ้อโกง หรือใช้หลายบัญซี คุณจะถูกตัดสิทธิ์ทันทีโดยไม่มีข้อยกเว้น และบัญชีของคณหรือสมาชิกคนอื่นๆ
				จะถูกระงับโดยอัตโนมัติ
			</div>

			<div class="cons">
				FAW99 ขอสงวนสิทธิ์การเปลี่ยนแปลง แก้ไข และยกเลิกโปรโมชั่นโดยไม่แจ้งให้ทราบล่วงหน้า
			</div>
			<div class="h20"></div>

			<!-- 弹窗 -->
			<div class="cover" v-if="isQueryDia"></div>
			<div class="dialog-query" v-if="isQueryDia">
				<div class="dia-top">
				<span class="t-queryresult">สอบถามสมาชิก</span>
				<img src="../assets/mobileimages/close.png" alt="" @click="isQueryDia = false">
				</div>
				<div>หมายเลขแบบทดสอบสมาชิกที่คุณกําลังมองหาคือ</div>
				<div class="flex item-box flex-js-between">
					<div><div>สองอันดับแรก</div><div class="num">{{p1_num}}</div></div>
					<div><div>หลังสองตำแหน่ง</div><div class="num">{{p2_num}}</div></div>
				</div>
				<div class="txt-a">ผลการจับสลาก:<b class="txt-b">ไม่ชนะ</b></div>
				<div><input class="item-inps" type="text" placeholder="ป้อนหมายเลขสมาชิกที่คุณต้องการสอบถาม" v-model="pusername" /></div>
				<div class="btn-boxs" @click="search()">ส่ง</div>
			</div>

		</div>
		<div class="footer">
			<p class="dp-flex-row flex-ai-center flex-js-center">Copyright &copy; {{ footerData }} Reserved</p>
			<!-- <p class="dp-flex-row flex-ai-center flex-js-center">Copyright &copy;wg88 Reserved</p> -->
		</div>
	</div>
</template>

<script>
export default {
	name: "HomePage",
	data() {
		return {
			timer: null,
			timer2: null,
			pcLogoUrl: "",
			navIndex: 0,
			navList: [{
				navName: "",
				imgSrc: "",
				url: "",
				id: null,
			}],
			// langOptions: [{
			// 		value: "th",
			// 		label: "ไทย",
			// 		icon: "flag_thai.png",
			// 		currency: 'THB',
			// 	},
			// 	{
			// 		value: "en",
			// 		label: "English",
			// 		icon: "flag_america.png",
			// 		currency: 'THB',
			// 	},
			// 	{
			// 		value: "km",
			// 		label: "កម្ពុជា។",
			// 		icon: "flag_cambodia.png",
			// 		currency: 'USD',
			// 	},
			// 	{
			// 		value: "zh",
			// 		label: "中文",
			// 		icon: "flag_china.png",
			// 		currency: 'USD',
			// 	},
			// ],
			whatsapp: [],
			selLanguage: {},
			footerData: "",
			nowLang: "th",
			configData: [],
			n: null,

			username: '',
			first_2: '',
			last_2: '',
			id:'',
			num_a:99,
			num_b:99,
			num_c:99,
			num_d:99,
			isQueryDia: false,
			pusername: '',
			p1_num:'??',
			p2_num:'??',
		};
	},
	created() {
		this.getConfigData();
		this.getList();
		this.navList[0].navName = this.$t("lang.vip_query");

		let queryLang = this.$route.query.lang;
		let lang = localStorage.getItem("language") || "th";
		if (queryLang == "th" || queryLang == "en" || queryLang == "km" || queryLang == "zh") {
			lang = queryLang;
		}
		this.nowLang = lang;
		this.$i18n.locale = lang;
		// this.selLanguage = this.langOptions.find(item => {
		// 	return item.value == this.$i18n.locale;
		// });
	},
	mounted() {

	},

	methods: {
		getList() {
			this.$get("/api/Lottery/index").then(res => {
				if(res.data.data.first_2){
					this.num_a = res.data.data.first_2.substr(0,1)
					this.num_b = res.data.data.first_2.substr(1,1)
				}
				if(res.data.data.last_2){
					this.num_c = res.data.data.last_2.substr(0,1)
					this.num_d = res.data.data.last_2.substr(1,1)
				}
				this.id = res.data.data.id
			})
		},
		changeClick(item) {
			if (item.id == 3) {
				return
			} else {
				window.open(item.url)
			}
		},
		submit() {
			if (!this.first_2) {
				alert("กรุณากรอกตัวเลข 2 หลักแรก")
				return
			} 
			if (!this.last_2) {
				alert("กรุณากรอกตัวเลข 2 หลักสุดท้าย")
				return
			} 
			if (!this.username){
				alert("กรุณากรอกชื่อผู้ใช้")
				return
			} 
			this.$get("/api/Lottery/bet", {
				username: this.username,
				first_2: this.first_2,
				last_2: this.last_2,
				id: this.id,
			}).then(res => {
				//if (res.data.code == 1) {
					alert(res.data.msg)
				//}
			})
		},
		search() {
			this.$get("/api/Lottery/Query", {
				username: this.pusername,
			}).then(res => {
				if (res.data.code == 0) {
					this.p1_num = res.data.data.first_2
					this.p2_num = res.data.data.last_2
				}else {
					alert(res.data.msg)	
				}
			})
		},
		getConfigData() {
			this.$get("/api/sysconf.html").then(res => {
				if (res.data.code == 0) {
					this.configData = res.data.data;
					this.whatsapp = res.data.data.whatsapp_cs_url;
					this.footerData = this.configData.site_copy;
					this.pcLogoUrl = this.configData.pc_logo;
					this.customBeginDate = this.configData.custom_begin_date;
					var regEx = new RegExp("\\-", "gi");
					if (this.nowLang != "zh") {
						this.customBeginDate = this.customBeginDate.replace(regEx, "/");
					}
					if (this.nowLang == "zh") {
						let msg1 = {
							navName: this.$t("lang.home_page"),
							url: [this.configData.zw_home_url],
							imgSrc: ""
						};
						this.navList.push(msg1);
						let msg2 = {
							navName: this.$t("lang.preferential_activities"),
							url: [this.configData.zw_promotion_url],
							imgSrc: ""
						};
						this.navList.push(msg2);
					}

					if (this.nowLang == "en") {
						let msg1 = {
							navName: this.$t("lang.home_page"),
							url: [this.configData.yy_home_url],
							imgSrc: ""
						};
						this.navList.push(msg1);
						let msg2 = {
							navName: this.$t("lang.preferential_activities"),
							url: [this.configData.yy_promotion_url],
							imgSrc: ""
						};
						this.navList.push(msg2);
					}
					if (this.nowLang == "km") {
						let msg1 = {
							navName: this.$t("lang.home_page"),
							url: [this.configData.jpz_home_url],
							imgSrc: ""
						};
						this.navList.push(msg1);
						let msg2 = {
							navName: this.$t("lang.preferential_activities"),
							url: [this.configData.jpz_promotion_url],
							imgSrc: ""
						};
						this.navList.push(msg2);
					}

					if (this.nowLang == "th") {
						let msg1 = {
							navName: this.$t("lang.home_page"),
							url: [this.configData.ty_home_url],
							imgSrc: ""
						};
						this.navList.push(msg1);
						let msg2 = {
							navName: this.$t("lang.preferential_activities"),
							url: [this.configData.ty_promotion_url],
							imgSrc: ""
						};
						this.navList.push(msg2);
						let msg3 = {
							navName: this.$t("lang.quizNav"),
							url: [location.href],
							imgSrc: "",
							id: 3
						};
						this.navList.push(msg3);
					}
					if (this.configData.cs_url) {
						let msg3 = {
							navName: this.$t("lang.online_service"),
							url: [this.configData.cs_url],
							imgSrc: ""
						};
						this.navList.push(msg3);
					}

				}
			});
		},
		changeNav(index) {
			this.navIndex = index;
		},
		// changeLaguages(item) {
		// 	this.$i18n.locale = item.value;
		// 	this.$i18n.currency = item.currency;
		// 	this.selLanguage = item;
		// 	localStorage.setItem("language", item.value);
		// 	localStorage.setItem("currency", item.currency);
		// 	this.$router.replace("/");
		// 	location.reload();
		// },
	},

	beforeDestroy() {
		clearInterval(this.timer);
		clearInterval(this.timer2);
	}
};
</script>


<style scoped lang="scss">
.homepage {
	background-color: #07000e;
}

.footer {
	width: 100%;
	height: 76px;
	background-color: #1a1a1a;
	color: #4e4e4e;

	p {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
	}
}

.langBox {
	position: absolute;
	right: 0;
	top: 20px;
	margin-left: 100px;
	padding: 10px;
	background-image: linear-gradient(0deg, #53005d 0%, #670081 50%, #7a00a2 100%), linear-gradient(#000000, #000000);
	background-blend-mode: normal, normal;
	border-radius: 4px;

	span {
		color: #fff;
		font-size: 15px;
	}

	img {
		width: 20px;
		height: 14px;
		margin-right: 5px;
	}

	.selLang {
		cursor: pointer;
	}
}

.nav {
	height: 100px;

	.cen-nav {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		position: relative;

		.img-logo {
			height: 70%;
			width: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			margin-left: 20px;
		}

		.nav-content {
			height: 100%;
			margin-left: 30px;

			ul {
				position: relative;
				top: 0;
				list-style-type: none;
				font-size: 22px;
				color: #fff;

				li {
					margin-left: 5px;
					position: relative;
					padding: 20px 10px;

					.navitem {
						cursor: pointer;
						padding: 20px 10px;
						position: relative;

						&.active {
							color: #ffe10b;
						}
					}

					img {
						width: 20px;
						height: 20px;
						position: absolute;
						top: 15px;
						right: -10px;
					}

					.navline {
						width: 100%;
						position: absolute;
						bottom: -20px;
						bottom: 0;
						height: 4px;
						left: 0;
						background-color: #ffe10b;
					}
				}
			}
		}
	}
}

.main {
	width: 1200px;
	min-height: 100%;
	margin: 0 auto;
	background: #1d0a27 url("../assets/pc/bg.jpg") top/100% no-repeat;
	font-size: 24px;
	color: #fff;

	.pic-box {
		background: url("../assets/pc/kbg.png") center no-repeat;
		margin: 0 auto;

		.pc-box {
			padding: 400px 300px 600px 300px;

			div {
				width: 25%;
				background-size: 100% 100%;
				text-align: center;

				img {
					width: 80%;
					margin: 0 auto;
				}
			}
		}
	}

	.input-box {
		margin-top: -500px;
		padding: 0 300px;

		.title {
			font-size: 30px;
			color: #e2e2ff;
			margin-bottom: 18px;
		}

		.item-inp {
			width: 552px;
			height: 40px;
			background-color: #000000;
			border-radius: 10px;
			border: solid 4px #b424f5;
			padding: 0 20px;
		}

		.txt {
			margin-left: 10px;
		}

		.pr {
			width: 210px;
			margin-bottom: 20px;
		}

		.pl {
			width: 210px;
			margin-bottom: 20px;
		}

		input {
			color: #fff !important;
			font-size: 24px;
		}
	}

	.btn-box {
		margin: 45px 450px;
		text-align: center;
		line-height: 62px;
		width: 300px;
		height: 62px;
		background-color: #b424f5;
		border-radius: 8px;
		border: solid 0px #b424f5;
		text-align: center;
	}

	.tip {
		color: #efc574;
		font-size: 30px;
		padding: 0 150px;
	}

	.con {
		background-color: #39104e;
		font-size: 24px;
		margin: 16px 120px;
		line-height: 32px;
		padding: 26px;
		border-radius: 14px;
	}

	.cons {
		font-size: 24px;
		text-align: center;
	}

	.h20 {
		height: 64px;
	}


	.record {
			width: 100%;
			margin-left: 800px;
			font-size: 32px;
			padding: 0 60px;
			color: #b424f5;
			min-height: 5rem;
		}
	
	//弹窗
	.cover {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 100;
		}

	.dialog-query {
			position: fixed;
			z-index: 200;
			width: 800px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			min-height: 20px;
			background-color: #07000e;
			border-radius: 24px;
			border: solid 2px #b424f5;
			
			border-radius: 5px;
			font-size: 28px;

			text-align: center;
			padding: 40px 80px;

			.dia-top {
				width: 100%;
				height: 80px;
				font-size: 42px;
				position: relative;
				font-weight: bold;
				.t-queryresult {
					text-align: center;
					width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				img {
					width: 32px;
					height: 32px;
					position: absolute;
					top: 12px;
					right: 5px;
					cursor: pointer;
				}
			}

			.item-inps {
				width: 90%;
				height: 60px;
				line-height: 60px;
				font-size: 32px;
				background-color: #000000;
				color: #fff;
				border-radius: 10px;
				border: solid 4px #b424f5;
				padding: 0 30px;
			}
			.num {
				font-weight: bold;
				font-size: 60px;
			}

			.txt-a {
				line-height: 60px;
				color: #b424f5;
				.txt-b{
					color: #f3f524;
				}
			}
			.btn-boxs {
				margin: 30px 240px;
				text-align: center;
				line-height: 60px;
				height: 60px;
				background-color: #b424f5;
				border-radius: 10px;
				border: solid 0px #b424f5;
			}
		}

}</style>
